<template>
  <div>
    <section class="footer-top-area pt-70 pb-30 pos-r bg-blue">
      <div class="container">
        <div class="row row-tb-20">
          <div class="col-xs-12">
            <div class="footer-links">
              <!-- <h3 class="color-lighter">快速访问</h3> -->
              <ul class="footer-links-list">
                <li><router-link to="/terms">使用条款</router-link></li>
                <!-- <li><router-link to="/faq">客户服务</router-link></li> -->
                <li><router-link to="/contact">联系我们</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="payment-methods t-center">
              <span><img src="@/assets/images/icons/payment/vcpay.jpg" alt="" /></span>
              <span><img src="@/assets/images/icons/payment/ylpay.jpg" alt="" /></span>
              <span><img src="@/assets/images/icons/payment/alipay.jpg" alt="" /></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer id="mainFooter" class="main-footer">
      <div class="container">
        <div class="row">
          <!-- <p>Copyright &copy; 粤ICP备2023123419号-1</p> -->
          <p> 粤ICP备2023123419号-1</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>

<style scoped>
/* .container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.col-xs-12 {
  flex: 1; 
  padding: 0 15px; 
}

.col-xs-12:last-child {
  width: 400px;
}

@media (max-width: 768px) {
  .col-xs-12 {
    flex-basis: 100%; 
  }
} */


.footer-links {
  max-width: 600px;
  margin: 0 auto; 
}

.footer-links-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.footer-links-list li {
  margin: 0;
}


.footer-links h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #fff; 
}

.footer-links-list a {
  color: #fff; 
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-links-list a:hover {
  color: #ffcc00; 
}

.payment-methods img {
  max-width: 50px;
  margin: 0 5px; 
}

.main-footer {
  background-color: #333; 
  color: #fff;
  text-align: center;
  padding: 10px 0;
}
</style>
