//引入mockjs插件开始模拟数据
import Mock from 'mockjs';
//引入数据：JSON数据格式数据
//比如:图片资源、JSON资源【里面不能书写export关键字】，这些资源默认对外暴露【默认暴露】
import temp from '@/mock/temp'
import home from '@/mock/home'
import couponList from '@/mock/couponList'

import coupons from '@/mock/coupons'
import shops from '@/mock/shops'
import users from '@/mock/users'
import order from '@/mock/bill'


//接口:相当于nodejs里面中间件
//第一个参数：接口的地址 第二个参数:向这个接口发请求获取到的数据 
//Mock插件：中间件默认是GET请求
Mock.mock("/mock/temp", { code: 200, data: temp });

//首页数据
Mock.mock("/mock/homeData", { code: 200, data: home, message: 'Home data fetched successfully.' });

//couponList数据
Mock.mock("/mock/couponList", { code: 200, data: couponList, message: 'couponList data fetched successfully.' });

//coupons shops users
Mock.mock("/mock/coupons", { code: 200, data: coupons, message: 'coupons data fetched successfully.' });
Mock.mock("/mock/shops", { code: 200, data: shops, message: 'shops data fetched successfully.' });
Mock.mock("/mock/users", { code: 200, data: users, message: 'users data fetched successfully.' });

//order
Mock.mock("/mock/order", { code: 200, data: order, message: 'order detail data fetched successfully.' });