//这是home的小仓库
let state = {
    username: null
};

let mutations = {
    setUsername(state, username) {
        console.log('vuex_user', username)
        state.username = username;
    }
};

let actions = {
    // 获取用户信息
    getusername({ commit, state }) {
        console.log("获取用户信息");
        let userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            commit('setUsername', JSON.parse(userInfo).username);
        }
    }
};

let getters = {};

//默认暴露小仓库
export default {
    namespaced: true, // 开启命名空间
    state,
    mutations,
    actions,
    getters
}