// 当打包构建应用时，JavaScript 包会变得非常大，影响页面加载。
// 如果我们能把不同路由对应的组件分割成不同的代码块，然后当路由被访问的时候才加载对应组件，这样就会更加高效。


export default [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () =>
            import ('@/pages/home'),
        meta: { show: true }
    },
    {
        path: '/coupons',
        component: () =>
            import ('@/pages/couponList'),
        meta: { show: true }
    },
    {
        path: '/deal',
        name: 'deal',
        component: () =>
            import ('@/pages/dealSingle'),
        meta: { show: true }
    },
    {
        path: '/cart',
        component: () =>
            import ('@/pages/cart'),
        meta: { show: true }
    },
    {
        path: '/order',
        component: () =>
            import ('@/pages/orderList'),
        meta: { show: true },
        // redirect: '/order/all', // 重定向到子路由
        // children: [{
        //         path: 'all',
        //         component: () =>
        //             import ('@/pages/orderList/allOrder'),
        //         meta: { show: true }
        //     },
        //     {
        //         path: 'backlog',
        //         component: () =>
        //             import ('@/pages/orderList/backlogOrder'),
        //         meta: { show: true },
        //         // redirect: '/order/all',
        //     },
        //     {
        //         path: 'complete',
        //         component: () =>
        //             import ('@/pages/orderList/completeOrder'),
        //         meta: { show: true },
        //         // redirect: '/order/all',
        //     },
        //     {
        //         path: 'aftersale',
        //         component: () =>
        //             import ('@/pages/orderList/afterSaleOrder'),
        //         meta: { show: true },
        //         // redirect: '/order/all',
        //     }
        // ]
    },
    {
        path: '/login',
        component: () =>
            import ('@/pages/login'),
        meta: { show: true }
    },
    {
        path: '/register',
        component: () =>
            import ('@/pages/register'),
        meta: { show: true }
    },
    {
        path: '/faq',
        component: () =>
            import ('@/pages/faq'),
        meta: { show: true }
    },
    {
        path: '/terms',
        component: () =>
            import ('@/pages/termsConditions'),
        meta: { show: true }
    },
    {
        path: '/contact',
        component: () =>
            import ('@/pages/contactUs'),
        meta: { show: true }
    },
    {
        path: '/bill',
        component: () =>
            import ('@/pages/checkoutBill'),
        meta: { show: true }
    },
    {
        path: '*', // 捕获所有未定义的路径
        component: () =>
            import ('@/pages/error'), // 返回404
        meta: { show: true }
    }
]