//从mock模拟接口获取数据
import { reqUsersData, reqCouponsData, reqShopsData } from "@/api"

//这是home的小仓库
let state = {
    couponsData: [],
    shopsData: [],
    usersData: [],
};

let mutations = {
    GETCOUPONSDATA(state, couponsData) {
        state.couponsData = couponsData
    },
    GETSHOPSDATA(state, shopsData) {
        state.shopsData = shopsData
    },
    GETUSERSDATA(state, usersData) {
        // console.log(initData)
        state.usersData = usersData
    }
};

let actions = {
    // 课程信息
    async getCouponsData({ commit, state, dispatch }) {
        let result = await reqCouponsData();
        if (result.status == 200) {
            commit('GETCOUPONSDATA', result.data.data)
                // console.log(result.data.data)
        }
    },
    // 机构信息
    async getShopsData({ commit, state, dispatch }) {
        let result = await reqShopsData();
        if (result.status == 200) {
            commit('GETSHOPSDATA', result.data.data)
                // console.log(result.data.data)
        }
    },
    // 用户信息-----评论
    async getUsersData({ commit, state, dispatch }) {
        let result = await reqUsersData();
        if (result.status == 200) {
            commit('GETUSERSDATA', result.data.data)
                // console.log(result.data.data)
        }
    }

};

let getters = {};

//默认暴露小仓库
export default {
    namespaced: true, // 开启命名空间
    state,
    mutations,
    actions,
    getters
}