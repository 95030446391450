import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import router from '@/router'
import store from '@/store'

// 在导入全局样式前，将jq导入
import 'jquery';
import 'bootstrap';



// const requireAll = (requireContext) => requireContext.keys().map(requireContext);


// 导入 assets/css 文件夹中的所有 .css 文件
// const cssModules = require.context('./assets/styles/css', true, /\.css$/);
// const jsModules = require.context('./assets/styles/js', true, /\.js$/);
// const fontModules = require.context('./assets/styles/fonts', true, /\.(woff2?|eot|ttf|otf)(\?.*)?$/);
// requireAll(cssModules);
// requireAll(jsModules);
// requireAll(fontModules);

// 全局引入css、js
import '@/assets/css/bootstrap.min.css';
import '@/assets/vendors/font-awesome/css/font-awesome.min.css';
import '@/assets/vendors/linearicons/css/linearicons.css';


import '@/assets/css/base.css';
import '@/assets/css/style.css';


import '@/assets/js/jquery-1.12.3.min.js';
import '@/assets/js/bootstrap.min.js';







// 全局组件
import Header from '@/components/header'
import Footer from '@/components/footer'
Vue.component(Header.name, Header);
Vue.component(Footer.name, Footer);

// elementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//引入mockServe文件，让mock模拟接口跑起来
import '@/mock/mockServe'
import { temp } from "@/api"



new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')