<template>
  <!-- –––––––––––––––[ HEADER ]––––––––––––––– -->
  <header id="mainHeader" class="main-header">

    <!-- Top Bar -->
    <div class="top-bar bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4 is-hidden-sm-down">
            <ul class="nav-top nav-top-left list-inline t-left">
              <li><router-link to="/terms"><i class="fa fa-question-circle"></i>条款和服务</router-link>
              </li>
              <!-- <li><router-link to="/faq"><i class="fa fa-support"></i>客户服务</router-link>
              </li> -->
            </ul>
          </div>
          <div class="col-sm-12 col-md-8">
            <ul class="nav-top nav-top-right list-inline t-xs-center t-md-right">
              <li class="sign" v-if="isLoggedIn">
                <a><i class="fa fa-user"></i>&nbsp;{{ username }}</a>
              </li>
              <li class="sign" v-else><a @click="loginHandler(1)"><i class="fa fa-lock"></i>&nbsp;登&nbsp;录</a></li>

              <li class="sign" v-if="isLoggedIn">
                <a @click="logout"><i class="fa fa-sign-out"></i>&nbsp;退出</a>
              </li>
              <li class="sign" v-else><a @click="loginHandler(0)"><i class="fa fa-user"></i>&nbsp;注&nbsp;册</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Bar -->

    <!-- Header Header -->
    <div class="header-header bg-white">
      <div class="container">
        <div class="row row-rl-0 row-tb-20 row-md-cell">
          <div class="brand col-md-3 t-xs-center t-md-left valign-middle">
            <router-link to="/home" class="logo">
              <!-- <img src="@/assets/images/logo.png" alt="" width="250"> -->
              <img src="@/assets/images/logoxy.png" alt="" width="250">
            </router-link>
          </div>
          <div class="header-search col-md-9 ">
            <div class="row row-tb-10 ">

              <div class="col-sm-3 t-xs-center t-md-right">
              </div>

              <div class="col-sm-8 t-xs-center t-md-right">
                <form class="search-form">
                  <div class="input-group">
                    <input type="text" class="form-control input-lg search-input" placeholder="请输入想搜索的课程" required="required">
                    <div class="input-group-btn">
                      <div class="input-group">
                        <select class="form-control input-lg search-select">
                          <option>广州</option>
                          <option>佛上</option>
                          <option>珠海</option>
                          <option>中山</option>
                        </select>
                        <div class="input-group-btn">
                          <router-link to="/coupons">
                            <button type="submit" class="btn btn-lg btn-search btn-block">
                              <i class="fa fa-search font-16"></i>
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Header Header -->

    <!-- Header Menu -->
    <div class="header-menu bg-blue">
      <div class="container">
        <nav class="nav-bar">
          <div class="nav-header">
            <span class="nav-toggle" data-toggle="#header-navbar">
              <i></i>
              <i></i>
              <i></i>
            </span>
          </div>
          <div id="header-navbar" class="nav-collapse">
            <ul class="nav-menu">
              <li :class="{ active: $route.path === '/home' }">
                <router-link to="/home">首页</router-link>
              </li>
              <li :class="{ active: $route.path === '/coupons' }">
                <router-link to="/coupons">课程</router-link>
              </li>
              <li :class="{ active: $route.path === '/cart' }">
                <router-link to="/cart">购物车</router-link>
              </li>
              <li :class="{ active: $route.path === '/order' }">
                <router-link to="/order">我的订单</router-link>
              </li>
              <li :class="{ active: $route.path === '/contact' }">
                <router-link to="/contact">联系我们</router-link>
              </li>
            </ul>
          </div>
          <div class="nav-menu nav-menu-fixed">
            <a href="##">Get Quote</a>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Header Menu -->

  </header>
  <!-- –––––––––––––––[ HEADER ]––––––––––––––– -->
</template>

<script>
export default {
  name: 'Header',

  data() {
    return {
      isLoggedIn: false,
      username: ''
    };
  },

  mounted() {
    this.getUser()
  },

  methods: {
    // mounted执行
    getUser() {
      // 检查本地存储中是否有用户信息
      const user = localStorage.getItem('userInfo');
      if (user) {
        this.isLoggedIn = true;
        this.username = JSON.parse(user).username; // 假设用户信息中包含用户名
      }
    },

    // 注册-------0 登录-------1
    loginHandler(num) {
      // this.$router.push({ path: '/login', query: { mode: num } });
      if (num === 0) {
        this.$router.push('/register')
      } else if (num === 1) {
        this.$router.push('/login')
      }
    },
    // 退出登录方法
    logout() {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('jwt');
      // 清空全部订单 购物车
      localStorage.removeItem('successOrderList');
      localStorage.removeItem('cartList');
      this.isLoggedIn = false;
      this.username = '';
      alert('退出成功');
      // 跳转到根路径
      this.$router.push('/');
    }
  },

  watch: {
    '$route'(to, from) {
      if (from.path === '/login') {
        this.getUser();
      }
    }
  },

};

</script>

<style  scoped>
.sign {
  margin-left: 12px;
}
</style>


