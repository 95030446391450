//从mock模拟接口获取数据
import { reqHomeData, reqCouponsData, reqShopsData } from "@/api"

//这是home的小仓库
let state = {
    initData: {},
    couponsData: [],
    shopsData: []
};

let mutations = {
    GETINITDATA(state, initData) {
        // console.log(initData)
        state.initData = initData
    },
    GETCOUPONSDATA(state, couponsData) {
        state.couponsData = couponsData
    },
    GETSHOPSDATA(state, shopsData) {
        state.shopsData = shopsData
    }
};

let actions = {
    // 获取初始信息
    async getInitData({ commit, state, dispatch }) {
        let result = await reqHomeData();
        if (result.data.code == 200) {
            commit('GETINITDATA', result.data.data)
        }
    },

    // 获取
    async getCouponsData({ commit, state, dispatch }) {
        let result = await reqCouponsData();
        if (result.status == 200) {
            commit('GETCOUPONSDATA', result.data.data)
                // console.log(result.data.data)
        }
    },
    // 获取商户信息
    async getShopsData({ commit, state, dispatch }) {
        let result = await reqShopsData();
        if (result.status == 200) {
            commit('GETSHOPSDATA', result.data.data)
                // console.log(result.data.data)
        }
    }

};

let getters = {};

//默认暴露小仓库
export default {
    namespaced: true, // 开启命名空间
    state,
    mutations,
    actions,
    getters
}