//从mock模拟接口获取数据
import { reqUsersData, reqCouponsData, reqShopsData } from "@/api"

//这是dealSingle的小仓库
let state = {
    couponsData: [],
    usersData: [],
    couponDetail: {},
    shopDetail: {},
};

let mutations = {
    GETUSERSDATA(state, usersData) {
        // console.log(initData)
        state.usersData = usersData
    },
    GETCOUPONSDATA(state, data) {
        state.couponsData = data.couponsData;
        const foundCoupon = data.couponsData.find(coupon => coupon.couponId == data.couponId);
        // const foundCoupon = data.couponsData.find(coupon => coupon.couponId == 1697096392290);
        state.couponDetail = foundCoupon;
        // console.log(foundCoupon, data.couponId)
    },
    GETSHOPDETAIL(state, data) {
        const foundShop = data.shopsData.find(shop => shop.shopName == data.shopName);
        // console.log(foundShop)
        state.shopDetail = foundShop;
    }
};

let actions = {
    // 用戶信息
    async getUsersData({ commit, state, dispatch }) {
        let result = await reqUsersData();
        if (result.status == 200) {
            commit('GETUSERSDATA', result.data.data)
                // console.log(result.data.data)
        }
    },
    //卷信息
    async getCouponsData({ commit, state, dispatch }, couponId) {
        let result = await reqCouponsData();
        if (result.status == 200) {
            commit('GETCOUPONSDATA', { couponsData: result.data.data, couponId: couponId })
                // console.log(couponId, { couponsData: result.data.data, couponId: couponId })
        }
    },
    //卷所属shop信息
    async getShopDetail({ commit, state, dispatch }, shopName) {
        let result = await reqShopsData();
        if (result.status == 200) {
            commit('GETSHOPDETAIL', { shopsData: result.data.data, shopName: shopName })
                // console.log(couponId, { couponsData: result.data.data, couponId: couponId })
        }
    }
};

let getters = {};

//默认暴露小仓库
export default {
    namespaced: true, // 开启命名空间
    state,
    mutations,
    actions,
    getters
}