import Vue from 'vue'
import Vuex from 'vuex'

//使用插件
Vue.use(Vuex);

//导入小仓库
import home from './home';
import couponList from './couponList';
import dealSingle from './dealSingle';
import user from './user';


export default new Vuex.Store({
    modules: {
        home,
        couponList,
        dealSingle,
        user
    }
})