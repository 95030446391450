<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    // localStorage.removeItem('cartList');
  }

}
</script>

<style>
#mainContent {
  background-color: #e9ebee;
}

.ellipsis1 {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
  -webkit-line-clamp: 1; /* 指定最多显示的行数 */
}

.ellipsis2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定最多显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis4 {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定最多显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis5 {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* 指定最多显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>


