import axios from 'axios';
import nprogress from 'nprogress' //添加进度条功能
import 'nprogress/nprogress.css' //不引入进度条没有样式

//axios.create方法返回一个可添加配置项的axios
const mockRequests = axios.create({
    //基础路径，发请求的URL携带api
    baseURL: '/mock',
    timeout: 5000
});
//请求拦截器：将来项目中每次请求都会触发
mockRequests.interceptors.request.use(config => {
    nprogress.start(); //发请求前进度条启用
    //请求拦截器：请求头【header】，请求头能给服务器携带参数
    //请求拦截器：其实项目中还有一个重要的作用，给服务器携带请求们的公共参数
    return config;
});
//响应拦截器
mockRequests.interceptors.response.use((res) => {
    nprogress.done(); //相应数据回来，进度条结束
    //res：实质是项目中发请求，服务器返回的数据
    return res;
}, (err) => {
    return new Promise();
});
//最后需要暴露   
export default mockRequests;