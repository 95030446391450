import request from "./request"

// 测试
export const reqEcho = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/echo', data })
}

// 获取验证密
export const reqGetVerifyCode = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/getVerificationCode', data })
}

// 注册
export const reqRegister = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/register', data })
}

// 登录
export const reqLogin = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/login', data })
}

// 
export const reqInfo = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/info', data })
}

// 更新token
export const reqRefreshToken = (data) => {
    return request({ method: 'post', url: '/front/xunyou/user/refreshToken', data })
}