import axios from 'axios';
import nprogress from 'nprogress'; // 添加进度条功能
import 'nprogress/nprogress.css'; // 引入进度条样式

const request = axios.create({
    baseURL: '/api', // 基础路径
    timeout: 5000 // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(config => {
    nprogress.start(); // 启动进度条
    // 获取localStorage中的jwt 中的token并存到请求头中Authorization字段中, 将userInfo中的username存到请求体中
    // 因后端部分接口需要token和username
    const jwt = localStorage.getItem('jwt');
    const userInfo = localStorage.getItem('userInfo');
    if (jwt) {
        config.headers.Authorization = JSON.parse(jwt).token;
    }
    if (userInfo) {
        config.data.username = JSON.parse(userInfo).username;
    }
    // 打印请求体
    // console.log('封装',config);
    // 在这里可以添加请求头等公共参数
    return config;
}, error => {
    // 请求错误处理
    nprogress.done();
    alert('请求发送失败');
    return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(response => {
    nprogress.done(); // 关闭进度条
    const res = response.data;
    if (response.status >= 200 && response.status < 300) {
        // 在这里获取请求的路径
        const requestUrl = response.config.url;
        // console.log(requestUrl)
        switch (res.code) {
            case 1000:
                // console.log('封装', res.data)
                return res.data;
            case 1001:
                if (res.message === "用户不存在") {
                    alert("用户名不存在或者密码不正确")
                } else {
                    alert(res.message);
                }
                return Promise.reject(new Error(res.message || 'Error'));
            case 1002:
                // TODO: 1002为验证失败，需要清除jwt和userInfo，并导向重新登录
                alert(res.message);
                return Promise.reject(new Error(res.message || 'Error'));
            case 1003:
                alert(res.message);
                return Promise.reject(new Error(res.message || 'Error'));
            case 1004:
                alert(res.message);
                return Promise.reject(new Error(res.message || 'Error'));
            default:
                alert('default', res.message);
                return Promise.reject(new Error(res.message || 'Error'));
        }
    } else {
        alert('else', response.message);
        return Promise.reject(new Error(response.message || 'Error'));
    }
}, error => {
    nprogress.done();
    alert(error.message);
    return Promise.reject(error);
});

export default request;