import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

//将路由放在单独文件
import routes from '@/router/routes'


// 重写$router.push、replace方法
// 先备份
let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => {}, () => {})
    }
}


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { y: 0 }
    }
});

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/bill' || to.path === '/cart' || to.path === '/order') {
        if (!localStorage.getItem('userInfo') || !localStorage.getItem('jwt')) {
            next('/login'); // 重定向到登录页面
        } else {
            next(); // 已登录，继续跳转
        }
    } else {
        next(); // 对于不需要登录的路由，直接放行
    }
});



export default router;